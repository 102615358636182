import { storeToRefs } from 'pinia'
import { useAuthStore } from '@/store/auth'
import { QUERY_STRING_KEYS } from '@/constants/query-string-keys'

export default defineNuxtRouteMiddleware(() => {
  if (process.server) return

  const { $localePath } = useNuxtApp() as any

  const { isLogin } = storeToRefs(useAuthStore())
  if (isLogin.value) {
    return
  }

  const route = useRoute()

  return navigateTo($localePath({ name: 'index', query: { [QUERY_STRING_KEYS.REDIRECT_URL]: route.fullPath } }), { replace: true })
})
